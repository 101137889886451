$ ->
	slider = $(".mediaSlider")
	if slider.length > 0
		slider.each (index, item)->
			slides = $(item).find("a.mediaSlider-image")
			overlay = $(".mediaOverlay")
			overlaySlides = overlay.find(".mediaOverlay-image")
			slides.click (e)->
				e.preventDefault()
				href = $(e.target).closest("a").attr "href"
				overlay.fadeIn()
				overlay.scrollLeft $(href).position().left

			overlay.click (e)->
				e.preventDefault()
				overlay.fadeOut()
